import { FC } from 'react';
import classnames from 'classnames';

import styles from './SbRespPlaceholderTile.scss';

type Props = {
    itemsInRow?: 2 | 5;
};

export const SbRespPlaceholderTile: FC<Props> = ({ itemsInRow }) => (
    <div
        className={classnames(styles.wrapper, {
            [styles.item5InRow]: itemsInRow === 5,
            [styles.item2InRow]: itemsInRow === 2,
        })}
    >
        <div className={styles.tile}>
            <div className={styles.image} />
            <div className={styles.title} />
            <div className={styles.subtitle} />
            <div className={styles.price} />
        </div>
    </div>
);
