/**
 * @generated SignedSource<<5f546b719e018c646eed9d703cf895fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedItemTile_item$data = {
  readonly isNewListing: boolean | null;
  readonly productPhotos: ReadonlyArray<{
    readonly smallPath: string | null;
    readonly versions: ReadonlyArray<{
      readonly webPath: string | null;
    } | null> | null;
  } | null> | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_item" | "FavoritesItem_item" | "ItemTileCarousel_item">;
  readonly " $fragmentType": "SharedItemTile_item";
};
export type SharedItemTile_item$key = {
  readonly " $data"?: SharedItemTile_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchVideo"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": "searchAndBrowse",
      "kind": "LocalArgument",
      "name": "pageDisplayEnum"
    },
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "photosLimit"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedItemTile_item",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fetchVideo",
          "variableName": "fetchVideo"
        },
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "pageDisplayEnum",
          "variableName": "pageDisplayEnum"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "showSeller",
          "variableName": "showSeller"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemTileCarousel_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesItem_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesItemProvider_item"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": "productPhotos",
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "photosLimit"
        }
      ],
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemPhotoVersion",
          "kind": "LinkedField",
          "name": "versions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webPath",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNewListing",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "6755e43470242e209a685607f428dda7";

export default node;
